import React from 'react';
import './InfoComponent.css';
import info from '../assets/info-button.svg'
import hand from '../assets/hand-instruction.png'
import { useNavigate } from 'react-router-dom';

/**
 * component to add instructions to image target
 */

interface InfoComponentProps {
  isOpen: boolean,
  setIsOpen: (bool: boolean) => void
}

const InfoComponent = ({ isOpen, setIsOpen }: InfoComponentProps) => {
  const changeOpen = () => {
    console.log('click');
    setIsOpen(!isOpen)
  }
  const navigate = useNavigate();

  return (
    <div id="info-component-container" onClick={() => navigate('/info')}>
      <div className="info-button">
        <img src={info} />
        Project Info
      </div>

      {isOpen && <div className='info-box'>
        <img src={hand} />
        Move around your phone to find a scannable reef
      </div>}


    </div>
  )
};

export { InfoComponent }