const imageHtml = `
  <a-scene id='ascene' 
    landing-page
      xrextras-loading
      xrextras-runtime-error
      renderer="colorManagement: true; physicallyBasedRendering: true;"
      xrweb="disableWorldTracking: true">

    <a-assets timeout="10000">
      <a-asset-item id="sticker-1.1-glb" src="${process.env.PUBLIC_URL}/assets/Models/ReefCloudAR_1_1.glb" ></a-asset-item>
      <a-asset-item id="sticker-1.3-glb" src="${process.env.PUBLIC_URL}/assets/Models/ReefCloudAR_1_3.glb" ></a-asset-item>
      <a-asset-item id="sticker-1.6-glb" src="${process.env.PUBLIC_URL}/assets/Models/ReefCloudAR_1_6.glb" ></a-asset-item>
      <a-asset-item id="sticker-1.7-glb" src="${process.env.PUBLIC_URL}/assets/Models/ReefCloudAR_1_7.glb" ></a-asset-item>
      <a-asset-item id="sticker-2.2-glb" src="${process.env.PUBLIC_URL}/assets/Models/ReefCloudAR_2_2.glb" ></a-asset-item>
      <a-asset-item id="sticker-2.4-glb" src="${process.env.PUBLIC_URL}/assets/Models/ReefCloudAR_2_4.glb" ></a-asset-item>
      <a-asset-item id="sticker-2.6-glb" src="${process.env.PUBLIC_URL}/assets/Models/ReefCloudAR_2_6.glb" ></a-asset-item>
      <a-asset-item id="sticker-2.8-glb" src="${process.env.PUBLIC_URL}/assets/Models/ReefCloudAR_2_8.glb" ></a-asset-item>
      

      <img id="partnership" src="${process.env.PUBLIC_URL}/assets/Pins/AR pin - partnerships.png"/>
      <img id="analysis" src="${process.env.PUBLIC_URL}/assets/Pins/AR pin - Analysis.png"/>
      <img id="reef" src="${process.env.PUBLIC_URL}/assets/Pins/AR pin - Reef.png"/>
      <img id="technology" src="${process.env.PUBLIC_URL}/assets/Pins/AR pin - technology.png"/>
    </a-assets>

    <a-camera
      position="0 8 1"
      raycaster="objects: .cantap"
      cursor="fuse: false; rayOrigin: mouse;">
    </a-camera>

    <a-light type="directional" intensity="1.5" position="0 1 0"></a-light>
    <a-light type="ambient" intensity="0.25"></a-light>

    <!-- WAVE LABEL (Cylindrical): video fades in on label 
    <xrextras-named-image-target name="wave-target" id="waveTarget" target-video>
    <div id="htmlElement" style="background: #F8F8F8; color: #333; font-size: 48px">Hello, HTML!</div>
    </xrextras-named-image-target> -->

    <!-- Sticker 1.1 -->
    <xrextras-named-image-target name="sticker-1.1" id="stickerTarget1">
      <a-image  
        id="fact-8" 
        name="fact-8" 
        class="cantap" 
        src="#partnership" 
        position="0 .7 0"
        width=".3"
        height=".3"
        >
      </a-image>

      <a-entity
        id="fishes"
        gltf-model="#sticker-1.1-glb"
        animation-mixer="clip: swimming; loop: repeat">
      </a-entity>

    </xrextras-named-image-target>

    <!-- STICKER 1.3 -->
    <xrextras-named-image-target name="sticker-1.3" id="stickerTarget1">
      <a-image  
        id="fact-7" 
        name="fact-7" 
        class="cantap" 
        src="#partnership" 
        position="-.5 .5 0"
        width=".3"
        height=".3">
      </a-image>

      <a-image  
        id="fact-1" 
        name="fact-1" 
        class="cantap" 
        src="#analysis" 
        position=".5 .5 0"
        width=".3"
        height=".3">
      </a-image>

      <a-entity
        id="fishes"
        gltf-model="#sticker-1.3-glb"
        animation-mixer="clip: swimming; loop: repeat">
      </a-entity>

    </xrextras-named-image-target>

    <!-- STICKER 1.6 -->
    <xrextras-named-image-target name="sticker-1.6" id="stickerTarget1">
      <a-image  
        id="fact-11" 
        name="fact-11" 
        class="cantap" 
        src="#reef" 
        position="0 .7 0"
        width=".3"
        height=".3">
      </a-image>

      <a-entity
        id="fishes"
        gltf-model="#sticker-1.6-glb"
        animation-mixer="clip: Take 001; loop: repeat">
      </a-entity>

    </xrextras-named-image-target>

    <!-- STICKER 1.7 -->
    <xrextras-named-image-target name="sticker-1.7" id="stickerTarget1">
      <a-image  
        id="fact-9" 
        name="fact-9" 
        class="cantap" 
        src="#technology" 
        position="0 .7 0""
        width=".3"
        height=".3">
      </a-image>

      <a-entity
        id="fishes"
        gltf-model="#sticker-1.7-glb"
        animation-mixer="clip: swimming; loop: repeat">
      </a-entity> 

    </xrextras-named-image-target>

    <!-- STICKER 2.2 -->
    <xrextras-named-image-target name="sticker-2.2" id="stickerTarget1">
      <a-image  
        id="fact-5" 
        name="fact-5" 
        class="cantap" 
        src="#reef" 
        position="0 .7 0""
        width=".3"
        height=".3">
      </a-image>

      <a-entity
        id="fishes"
        gltf-model="#sticker-2.2-glb"
        animation-mixer="clip: Take 001; loop: repeat">
      </a-entity>

    </xrextras-named-image-target>

    <!-- STICKER 2.4 -->
    <xrextras-named-image-target name="sticker-2.4" id="stickerTarget1">
      <a-image  
        id="fact-10" 
        name="fact-10" 
        class="cantap" 
        src="#partnership" 
        position="-.5 .5 0"
        width=".3"
        height=".3">
      </a-image>

      <a-image  
        id="fact-4" 
        name="fact-4" 
        class="cantap" 
        src="#reef" 
        position=".5 .5 0"
        width=".3"
        height=".3">
      </a-image>

      <a-entity
        id="fishes"
        gltf-model="#sticker-2.4-glb"
        animation-mixer="clip: swimming; loop: repeat">
      </a-entity> 

    </xrextras-named-image-target>

    <!-- STICKER 2.6 -->
    <xrextras-named-image-target name="sticker-2.6" id="stickerTarget1">
      <a-image  
        id="fact-6" 
        name="fact-6" 
        class="cantap" 
        src="#partnership" 
        position="0 .7 0""
        width=".3"
        height=".3">
      </a-image>

      <a-entity
        id="fishes"
        gltf-model="#sticker-2.6-glb"
        animation-mixer="clip: swimming; loop: repeat">
      </a-entity>

    </xrextras-named-image-target>

    <!-- STICKER 2.8 -->
    <xrextras-named-image-target name="sticker-2.8" id="stickerTarget1"> 
      <a-image  
        id="fact-2" 
        name="fact-2" 
        class="cantap" 
        src="#analysis" 
        position="-.5 .5 0"
        width=".3"
        height=".3">
      </a-image>

      <a-image  
        id="fact-3" 
        name="fact-3" 
        class="cantap" 
        src="#reef" 
        position=".5 .5 0"
        width=".3"
        height=".3">
      </a-image>

      <a-entity
        id="fishes"
        gltf-model="#sticker-2.8-glb"
        animation-mixer="clip: swimming; loop: repeat">
      </a-entity> 

    </xrextras-named-image-target>
  </a-scene>`;

export default imageHtml;
