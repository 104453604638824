import { configureStore, createSlice } from '@reduxjs/toolkit'



const slice = createSlice({
  name: 'reef',
  initialState: {
    selectedFact: 'fact-1',
    selectedSticker: undefined,
    learnMoreIcons: undefined,
    bottomDrawer: false
  },
  reducers: {
    setSelectedFact: (state, action) => {
      console.log('triggered', action)
      state.selectedFact = action.payload;
    },
    setSelectedSticker: (state, action) => {
      console.log('triggered')
      state.selectedSticker = action.payload;
    },
    setBottomDrawer: (state, action) => {
      console.log('triggered bottom drawer')
      state.bottomDrawer = action.payload;
    }
  }
})

// const booksSelectors = slice.getSelectors((state) => state.books)
export const store = configureStore({
  reducer: {
    slice: slice.reducer
  },
})

export const selectSelectedSticker = (state: any) => state.slice.selectedSticker;
export const selectSelectedFact = (state: any) => state.slice.selectedFact;
export const selectLearnMoreIcons = (state: any) => state.slice.selectedLearnMoreIcons;
export const selectBottomDrawer = (state: any) => state.slice.bottomDrawer;


export const { setSelectedFact, setSelectedSticker, setBottomDrawer } = slice.actions
export default slice.reducer
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch