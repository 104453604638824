import React from 'react';
import Image1 from '../assets/Images/InfoImage_1.png';
import Image2 from '../assets/Images/InfoImage_2.png';
import Image3 from '../assets/Images/InfoImage_3.png';
import Image4 from '../assets/Images/InfoImage_4.png';
import Image5 from '../assets/Images/InfoImage_5.png';
import './info-page.css';

  const slides = [
{
    id: 0,
    src: Image1,
    header: `Experience the reefs \nlike never before`,
    sub: "Learn about ReefCloud and watch the coral come alive in the palm of your hand."
 },
{
    id: 1,
    src: Image2,
    header: `Point your camera \nat a reef indicator`,
    sub: `Move your phone camera around \n until you spot a reef indicator.`,
},
{
    id: 2,
    src: Image3,
    header: 'Learn about facts surrounding a cluster',
    sub: 'Each reef indicator will have a cluster of information pins that will appear.' 
},
{
    id: 3,
    src: Image4,
    header: `Read more about \na given topic`,
    sub: `Clicking into a pin will show you more information about the pin's topic.`
},
{
    id: 4,
    src: Image5,
    header: `Enable your \ncamera access`,
    sub: 'You will be asked to enable your camera access to scan and view reef indicators.' 
}
];

export default slides;