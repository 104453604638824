import * as React from 'react';
import { useState, useEffect } from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import { InfoPage } from './screens/info-page';
import { GettingStartedAframe } from './experience/getting-started/Scene';
import { NotFound } from './screens/not-found';
import { ImageTarget } from './experience/image-target/ImageTarget';
import { BottomDrawer } from './components/BottomDrawer';
import { store } from './store'
import { Provider } from 'react-redux'
import { AboutPage } from './screens/AboutPage';
import LoadingScreen from './components/LoadingScreen';
import ErrorBoundary from "./components/ErrorBoundary";
import TabletWarning from './screens/TabletWarning';
import UploadAndDisplayImage from './components/UploadAndDisplayImage';

function App() {
    const [hideLoadingScreen, setHideLoadingScreen] = useState(false);
    const [isMobileScreen, setIsMobileScreen] = useState(false);

    useEffect(() => {
        window.addEventListener('xrloaded', () => {
            console.log('XR8 Loaded');
        })  
        
        const currentPath = window.location.pathname;
        if (window.innerWidth >= 450) {
            setIsMobileScreen(true);
        } 
        if (currentPath === '/') {
            const timer = setTimeout(() => {
                setHideLoadingScreen(true);
            }, 3000);
            return () => clearTimeout(timer);
        } else {
            setHideLoadingScreen(true);
        }
    
      });  

  return (
    <>
        { isMobileScreen && <TabletWarning /> }
        { hideLoadingScreen || <LoadingScreen /> }
        <Provider store={store}>
            <BrowserRouter>
                <ErrorBoundary>
                    <Routes>
                        <Route path={'/'} element={<InfoPage />} />
                        <Route path={'/image-target'} element={<ImageTarget />} />
                        <Route path={'/upload-images'} element={<UploadAndDisplayImage />} />
                        <Route path={'/bottom-drawer'} element={<BottomDrawer isOpen={(prop: any) => console.log(prop)} onClose={() => console.log()} />} />
                        <Route path={'/info'} element={<AboutPage />} />
                        <Route path='*' element={<NotFound />} />
                    </Routes>
                </ErrorBoundary>
            </BrowserRouter>
        </Provider>
    </>
      )
}

export default App;