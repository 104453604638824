import React, {useState} from 'react'
import './TabletWarning.css'
import ReefCloudLogo from '../assets/Media/tablet-warning-rf-logo.svg';

function TabletWarning() {
  const [hideTabletWarning, setHideTabletWarning] = useState(false);

  return (
      <div className={ 
        hideTabletWarning ? 'hide-tablet-warning' : 'tablet-warning-container'
      }>
        <div className='tablet-warning-bg'>
            <div className="tablet-warning-wrapper">
              <img className='rf-logo' src={ReefCloudLogo} />
              <h1 className='warning title'>For the best experience please use a mobile device.</h1>
              <p className='warning text'>It looks like you are on a non-mobile device. While you can still experience this project using this device, it is not recommended. If you would like to proceed, click the button below. Otherwise, please re-scan the QR code using your mobile device.</p>
              <button onClick={() => {setHideTabletWarning(true)}} className='continue-btn'>Continue</button>
            </div>
        </div>
      </div>
  );
}

export default TabletWarning;