import React from 'react';
import './TargetPopup.css'
import arrow from '../assets/arrow.png'
import { useDispatch } from 'react-redux'
import { setSelectedFact } from '../store'
import button from '../assets/learn-more-button.svg';

/**
 * component that popups when icon is clicked 
 */

export interface TargetPopupProps {
    style?: React.CSSProperties,
    logo: string,
    title?: string,
    caption?: string,
    link?: string,
    setBottomDrawer: (arg0: boolean) => void
}


const TargetPopup = ({ style, logo, title, caption, link, setBottomDrawer }: TargetPopupProps) => {
    const dispatch = useDispatch();
    const targetPopUp = document.getElementById('target-popup')
  
    const updateStore = () => {
        dispatch(setSelectedFact(link))
        setBottomDrawer(true);
    }

    return (
        <div id="target-container">
              <div id="blocker"></div>
            <div id="target-popup" style={style}>
                
                <img src={logo} className="filter-green"/>
                <div className="target-h1"> {title} </div>
                <p> {caption} </p>
                <div onClick={updateStore}>
                    <img src={button} className="target-arrow"/>
                    </div>
            </div>
        </div>
    )
};

export { TargetPopup }