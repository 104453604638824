import funFactImg from '../assets/Media/fun-fact-img.svg';
import Fact1 from '../assets/FunFactImages/f1.png';
import Fact2Image from '../assets/FunFactImages/f2.png';
import Fact3Image from '../assets/FunFactImages/f3.png';
import Fact4Image from '../assets/FunFactImages/f4.png';
import Fact5Image from '../assets/FunFactImages/f5.png';
import Fact6Image from '../assets/FunFactImages/f6.png';
import Fact7Image from '../assets/FunFactImages/f7.png';
import Fact8Image from '../assets/FunFactImages/f8.png';
import Fact9Image from '../assets/FunFactImages/f9.png';
import Fact10Image from '../assets/FunFactImages/f10.png';
import Fact11Image from '../assets/FunFactImages/f11.png';
import analysis from '../assets/Pins/AR pin - Analysis.svg';
import partnerships from '../assets/Pins/AR pin - partnerships.svg';
import reef from '../assets/Pins/AR pin - Reef.svg';
import technology from '../assets/Pins/AR pin - technology.svg';


export interface ICoralReefFacts {
  mainImage:string;
  scanIcon: string;
  factTopic: string;
  factTitle: string;
  previewText?: string;
  factText: string;
  factText2?: string;
  solutionTitle: string;
  solutionText: string;
  modelLink?: any;
}

const factTopics = {
  analysisTechniques: { name: 'Analysis Techniques', icon: analysis},
  coralReefs: { name: 'Coral Reefs', icon: reef},
  tech: { name: 'Technology', icon: technology},
  partnerships: { name: 'Partnerships', icon: partnerships},
}

const Models = {
  model_1_3: 'https://d1x147qfb8f7cq.cloudfront.net/view/public?api_token=5db454d0-10f3-4425-8fb5-9a8a3c80f977&tenant_id=reef-cloud-us-east-1_TQqtreE1O&model_id=Reef_Cloud_1_3_1669014999313&v=1',
  model_2_8: 'https://d1x147qfb8f7cq.cloudfront.net/view/public?api_token=5db454d0-10f3-4425-8fb5-9a8a3c80f977&tenant_id=reef-cloud-us-east-1_TQqtreE1O&model_id=Reef_Cloud_2_8_1669019152141&v=1',
  model_2_4: 'https://d1x147qfb8f7cq.cloudfront.net/view/public?api_token=5db454d0-10f3-4425-8fb5-9a8a3c80f977&tenant_id=reef-cloud-us-east-1_TQqtreE1O&model_id=Reef_Cloud_2_4_1669018553984&v=1',
  model_2_1: 'https://d1x147qfb8f7cq.cloudfront.net/view/public?api_token=5db454d0-10f3-4425-8fb5-9a8a3c80f977&tenant_id=reef-cloud-us-east-1_TQqtreE1O&model_id=Reef_Cloud_2_1_1669018296576&v=1',
  model_1_2: 'https://d1x147qfb8f7cq.cloudfront.net/view/public?api_token=5db454d0-10f3-4425-8fb5-9a8a3c80f977&tenant_id=reef-cloud-us-east-1_TQqtreE1O&model_id=Reef_Cloud_1_2_1669014710620&v=1',
  model_1_7: 'https://d1x147qfb8f7cq.cloudfront.net/view/public?api_token=5db454d0-10f3-4425-8fb5-9a8a3c80f977&tenant_id=reef-cloud-us-east-1_TQqtreE1O&model_id=Reef_Cloud_1_7_1669016244263&v=1',
  model_1_6: 'https://d1x147qfb8f7cq.cloudfront.net/view/public?api_token=5db454d0-10f3-4425-8fb5-9a8a3c80f977&tenant_id=reef-cloud-us-east-1_TQqtreE1O&model_id=Reef_Cloud_1_6_1669015912967&v=1',
  model_2_7: 'https://d1x147qfb8f7cq.cloudfront.net/view/public?api_token=5db454d0-10f3-4425-8fb5-9a8a3c80f977&tenant_id=reef-cloud-us-east-1_TQqtreE1O&model_id=Reef_Cloud_2_7_1669019009119&v=1'
}

export const CoralReefFacts = new Map<string, ICoralReefFacts>([
//sticker 1.3
['fact-1', {
  mainImage: Fact1,
  scanIcon: factTopics['analysisTechniques'].icon,
  factTopic: factTopics['analysisTechniques'].name,
  factTitle: 'Obtaining coral insights 700x faster',
  previewText: 'With AI and cloud computing, ReefCloud creates insights 700x faster',
  factText: 'To understand the health of our coral reefs, regular monitoring is essential. Traditionally, coral reef monitoring has been a slow and painstaking process.',
  solutionTitle: 'The solution',
  solutionText: 'ReefCloud uses machine learning (ML) algorithms to learn from experts to automate image analysis and extract relevant reef information quickly and efficiently. Because it accomplishes in seconds what used to take reef scientists 15 minutes, the platform has freed up months of skilled labour and precious reef management resources. This means marine scientists can now focus on informing policy instead of spending time wading through floods of data.',
  modelLink: Models['model_1_3']
}],
//sticker 2.8
['fact-2', {
  mainImage: Fact2Image,
  scanIcon: factTopics['analysisTechniques'].icon,
  factTopic: factTopics['analysisTechniques'].name,
  factTitle: 'As accurate as a human',
  previewText: 'ReefCloud achieves 80-90% accuracy – same as a human',
  factText: 'A large part of the development process is validating and adjusting the models until the machine is performing as well as a human annotator',
  solutionTitle: 'The solution',
  solutionText: 'Our goal is to achieve an error rate of less than 2% - better than the difference between two human annotators. Just like humans, our artificial intelligence finds some benthic components trickier to identify than others!',
  modelLink: Models['model_2_8']
}],
//sticker 2.8
['fact-3', {
  mainImage: Fact3Image,
  scanIcon: factTopics['coralReefs'].icon,
  factTopic: factTopics['coralReefs'].name,
  factTitle: '90% of corals may forever disappear',
  previewText: 'Over 450 ReefClouders around the world - and growing',
  factText: 'Coral reefs are sometimes called the rainforests of the sea — a fitting nickname, as both are ecologically diverse, structurally complex and critically endangered.',
  solutionTitle: 'The harsh reality',
  solutionText: '50% of the world\'s coral reefs are already severely damaged by climate change and chronic pollution and scientists estimate that 90% of the globe’s coral reefs will be gone by 2050 if we do nothing about it. It\'s a dismal data point that becomes a dire reality when you consider that reefs support at least 25% of marine species, not to mention the food source and livelihood for about one billion people worldwide.',
  modelLink: Models['model_2_8']
}],
//sticker 2.2
['fact-4', {
  mainImage: Fact4Image,
  scanIcon: factTopics['coralReefs'].icon,
  factTopic: factTopics['coralReefs'].name,
  factTitle: 'The increasing impact of cyclones',
  previewText: 'Extreme and more frequent cyclones have an increasing impact on corals.',
  factText: 'If sufficiently long lasting, the extreme winds generated by these storms can build powerful waves which can severely damage coral reefs.',
  solutionTitle: 'The solution',
  solutionText: 'ReefCloud models where extreme waves could form during a given cyclone and predicts a cyclone\'s \'damage zone\' for corals to help reef managers to spatially target management responses after major cyclones to promote recovery and identify spatial patterns in historic cyclones exposure to explain habitat condition trajectories.',
  modelLink: Models['model_2_4']
}],
//sticker 2.2
['fact-5', {
  mainImage: Fact5Image,
  scanIcon: factTopics['coralReefs'].icon,
  factTopic: factTopics['coralReefs'].name,
  factTitle: 'Corals are feeling the heat too!',
  previewText: 'Severe coral bleaching has become more extensive over the past 10 years. ',
  factText: 'First observed in the early 1980s, mass coral bleaching has become one of the most visible and damaging marine ecological impacts of climate change.',
  factText2: 'When water temperature is above the average maximum summer temperature for extended periods of time corals can become thermally stressed, leading to coral bleaching and eventually loss of coral. Over the past decade, severe coral bleaching has become more extensive, frequent, and intense.',
  solutionTitle: 'The Solution',
  solutionText: 'ReefCloud uses research from NOAA\'s Coral Reef Watch to provide insights into thermal stress events for corals to help reef managers to better manage affected areas.',
  modelLink: Models['model_2_1']
}],
//sticker 2.6
['fact-6', {
  mainImage: Fact6Image,
  scanIcon: factTopics['partnerships'].icon,
  factTopic: factTopics['partnerships'].name,
  factTitle: 'Thousands of years of knowledge',
  previewText: 'We work with Traditional Owners to combine western and traditional knowledge. ',
  factText: 'It is estimated that indigenous people manage or have tenure rights over 1/4 of the land base on earth which remain highly intact and biodiverse areas.',
  solutionTitle: 'Taking ownership',
  solutionText: 'While western science has only been gaining insights into marine ecosystems for the last 30 years, Traditional Owner groups have a wealth of knowledge stretching over millennia. ReefCloud allows Traditional Owners, such as the Bardi Jawi community in Western Australia, to take ownership of their data and create insights to manage their marine ecosystems while facilitating a valuable knowledge exchange between western science and traditional knowledge.',
  modelLink: Models['model_2_7']
}],

//sticker 1.3
['fact-7', {
  mainImage: Fact7Image,
  scanIcon: factTopics['partnerships'].icon,
  factTopic: factTopics['partnerships'].name,
  factTitle: 'Fiji\'s tour guides becoming researchers',
  previewText: 'Tourism guides across Fiji use ReefCloud to support coral reef research.',
  factText: 'Fiji\'s tourism industry, much of it centred around coral reefs, was heavily impacted by COVID-19. Lockdowns prevented coral reef scientists from travelling to collect important coral reef data.',
  solutionTitle: 'The solution',
  solutionText: 'AIMS and Wildlife Conservation Society (WCS) Fiji worked with Fiji\'s tourism operators to use ReefCloud to capture key reef health data that would otherwise be lost because of the pandemic. Marine biologists employed as tourism guides in various resorts across Fiji\'s islands were trained to collect photo data which was then analysed by WCS using ReefCloud to remotely monitor reef health during the pandemic.',
  modelLink: Models['model_1_3']
}],

//sticker 1.1
['fact-8', {
  mainImage: Fact8Image,
  scanIcon: factTopics['partnerships'].icon,
  factTopic: factTopics['partnerships'].name,
  factTitle: 'Research securely stored in the cloud',
  previewText: 'From hard drives to securely stored in the cloud – Palau makes the move ',
  factText: 'While the Palau International Coral Reef Center (PICRC) has one of the best-established monitoring programs in the Pacific, gathering data is labour-intensive and time consuming.',
  factText2: 'A powerful typhoon in 2010 destroyed over 60% of coral reefs on the nation\'s east coast along with important hard drives containing PICRC\'s reef monitoring data.',
  solutionTitle: 'The solution',
  solutionText: 'PICRC now uses ReefCloud which is saves up to four months of labour a year in data processing time, providing PICRC scientists with more time for science and reef management. Plus, as the data will now be securely saved in the cloud it is secured from damaging weather events and makes collaboration with other organisations much easier.',
  modelLink: Models['model_1_2']
}],
//sticker 1.7
['fact-9', {
  mainImage: Fact9Image,
  scanIcon: factTopics['tech'].icon,
  factTopic: factTopics['tech'].name,
  factTitle: 'Protecting coral reefs - sustainably',
  previewText: 'Using AWS, ReefCloud reduces emissions while increasing workload ',
  factText: 'Carbon emissions are the main cause of climate change impacting our coral reefs. Using AWS, ReefCloud has been able to save 50% of carbon emissions compared to on-premises computing equivalents.',
  solutionTitle: 'The solution',
  solutionText: 'With over 65 million data points to analyse, ReefCloud benefits from AWS data centres using ML algorithms to optimise the efficiency of workload placement. ReefCloud further reduced carbon emissions on AWS by 75% by migrating from EC2 servers to Elastic Beanstalk to Lambda and batch services, which enables ReefCloud to run functions only when needed and therefore keep emissions at a minimum while ReefCloud\'s usage and workload have more than doubled in the same timeframe.',
  modelLink: Models['model_1_7']
}],
//sticker 2.4
['fact-10', {
  mainImage: Fact10Image,
  scanIcon: factTopics['partnerships'].icon,
  factTopic: factTopics['partnerships'].name,
  factTitle: 'A global community for corals',
  previewText: 'Over 450 ReefClouders around the world – and growing.',
  factText: 'With over 450 users around the globe and data from across 44 countries, ReefCloud is rapidly expanding its impact and supporting coral reef monitoring globally.',
  solutionTitle: 'Uniting the community',
  solutionText: 'In 2020, the Global Coral Reef Monitoring Network provided a global snapshot of coral reef condition from over 12,000 coral reef sites in 73 countries. With limited human resources it took years to painstakingly reconcile inconsistent data formats and methodologies to create this report. ReefCloud unites the global coral reef monitoring community to overcome these challenges through automation and standardisation of data analysis to provide quicker insights and inform decisions on  coral reef health.',
  modelLink: Models['model_2_4']
}],
//sticker 1.6
['fact-11', {
  mainImage: Fact11Image,
  scanIcon: factTopics['coralReefs'].icon,
  factTopic: factTopics['coralReefs'].name,
  factTitle: '50% of corals already destroyed',
  previewText: 'Climate change, overfishing and pollution are impacting our reefs globally. ',
  factText: 'While coral reefs cover less than 1% of the ocean floor, they support 25% of all marine life and provide food, income and livelihood as well as protection from storms to over 1 bn people globally.',
  solutionTitle: 'The harsh reality',
  solutionText: 'Coral reefs create an annual economic value of US $375bn. Climate change is however threatening our reefs. 50% of corals have already been lost and 90% will be critically endangered by 2050 if nothing is done. We\'re at an important crossroads for the future of coral reefs in the world. According to a report by the WWF, if our reefs were healthy and managed sustainably, they would create additional economic benefits of over US $50bn per year.',
  modelLink: Models['model_1_6']
}],

]);