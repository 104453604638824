import axios from "axios";

const API = 'https://v7vgz8ub02.execute-api.us-east-1.amazonaws.com/dev/'

interface ErrorInterface {
    location: string;
    error: string;
    errorInfo?: string;
}

const logErrorToMyService = (error: any, errorInfo?: any) => {
    console.log('Error Service Triggered');
    console.log(errorInfo);
    console.log(error);

    const errorObject: ErrorInterface = { location: window.location.hostname, error: error };
    if (errorInfo) {
        errorObject.errorInfo = errorInfo;
    }

    axios.post(API + 'log-errors', errorObject).then((response) => {
        console.log(response);
    })
}

export default logErrorToMyService;