import React, { useState, useRef, useEffect } from 'react'
import './BottomDrawer.css';
import BottomSheet from "react-draggable-bottom-sheet";
import { Sheet, Header, Content, Footer, detents, Portal, } from 'react-sheet-slide'
import 'react-sheet-slide/style.css'
import drawerImage from '../assets/drawerImage.png'
import Divider from '../assets/Divider.png'
import waveWhite from '../assets/waveWhite.png'
import Vector from '../assets/Vector.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux'
import { selectSelectedFact } from '../store';
import { CoralReefFacts, ICoralReefFacts } from '../Data/CoralReefFacts';
import infoIcon from '../assets/info-button1.svg'
import elixr from '../assets/elixr.svg'

import { useNavigate } from 'react-router-dom';


interface BottomDrawerProps {
  mainImage?: string,
  scanIcon?: string,
  eyeBrowTopic?: string,
  funFactTitle?: string,
  funFactText?: string,
  solutionTitle?: string,
  solutionText?: string,
  isOpen: (arg0: boolean) => void,
  onClose: () => void
}
const BottomDrawer = ({ isOpen, onClose }: BottomDrawerProps) => {

  const [open, setOpen] = useState(true)

  const ref = useRef()
  console.log('bottom drawer open', open)

  const selectedFact = useSelector(selectSelectedFact);
  const navigate = useNavigate();

  const [data, setData] = useState<ICoralReefFacts | undefined>(CoralReefFacts.get(selectedFact))

  useEffect(() => {
    isOpen(open);
  }, [open])

  useEffect(() => {
    return () => {
      onClose();
    }

  }, [])

  return (
    <div className="rss-backdrop"  >
      <button type="button" onClick={() => setOpen(true)} style={{ display: 'flex', margin: '28px auto 0' }}>
        Open sheet
      </button>
      <Portal>
        <div className="popup-wrapper" id="bottom-drawer">
          <Sheet
            //ref={ref}
            open={open}
            onDismiss={() => setOpen(false)}
            useModal={false}
            onClose={() => {
              console.log('Component unmounted');
            }}
            selectedDetent={() => 590}
            detents={props => [
              detents.large(props),
              detents.medium(props),
              props.minHeight = 590
            ]}
            scrollingExpands={true}
          >
            <Header>
            </Header>
            {data && <Content>
              <div className="header-wrapper">
                <img src={data?.mainImage} className='header-image' />
              </div>
              <div className="content-wrapper">
                <img className="scan-icon" src={data?.scanIcon} />
                <h2 className='eyebrow-topic'>{data?.factTopic}</h2>
                <h1 className="fun-fact-title">{data?.factTitle}</h1>
                <p className='fun-fact-text'> {data?.factText}</p>
                <p className='fun-fact-text'> {data?.factText2}</p>
                <img src={Divider} className='solid' />
                <h3 className="solution-title">{data?.solutionTitle}</h3>
                <p className='solution-text'> {data?.solutionText} </p>
                <span className="bottomDrawer-span">

                <div onClick={() => window.open(data?.modelLink, "_blank")} >
                  <img src={elixr} />
                  {/* <h3 className="project-info-text">View 3d model</h3> */}
                </div>

                <div onClick={() => navigate('/info')}>
                  <img src={infoIcon} />
                  {/* <h3 className="project-info-text">Project Info</h3> */}
                </div>

                </span>
              </div>
            </Content>}
            <Footer>
              <div className="footer-wrapper">
              </div>
            </Footer>
          </Sheet>
        </div>
      </Portal>
    </div>
  )
}


export { BottomDrawer }