import React, {ErrorInfo} from "react";
import './ErrorBoundary.css';
import largeInfo from '../assets/info-large.svg';
import logErrorToMyService from "../services/ErrorService";

type MyProps = {
    children: any
};
type MyState = { hasError: boolean };

class ErrorBoundary extends React.Component<MyProps, MyState> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        logErrorToMyService(error, errorInfo);
    }

    componentDidMount() {
        window.onerror = (message, file, line, column, errorObject) => {
            if (errorObject) {
                logErrorToMyService(errorObject.message, errorObject.stack);
            }
        }
    }

    somethingWentWrong = 'Oops! Something \n went wrong?'

    render() {
        const {hasError} = this.state;
        if (hasError) {
            // You can render any custom fallback UI
            return (
                <div className="container">
                    <div className="header">
                        <img className="header-icon" src={largeInfo} alt="large info icon"/>
                        <h1>{this.somethingWentWrong}</h1>
                    </div>
                    <div className="body">
                        <p>
                            Oops! It looks like something went wrong
                            loading this information. Close out and try again?
                        </p>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;