import React, { useEffect, useState } from 'react';
import { InfoComponent } from '../../components/InfoComponent';
import { TargetPopup, TargetPopupProps } from '../../components/TargetPopup';
import { AFrameScene } from '../aframe-components';
import imageHtml from './image-target-html';
import { imagePopups } from './ImageTarget.helpers';
import { BottomDrawer } from '../../components/BottomDrawer';
import { useDispatch, useSelector } from 'react-redux'
import { selectSelectedFact, setSelectedFact } from '../../store';
import { CoralReefFacts } from '../../Data/CoralReefFacts';

const ImageTarget = () => {
  const [hideInfo, setHideInfo] = useState(false);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(true);
  const [showBottomDrawer, setShowBottomDrawer] = useState(false);
  const [sticker, setSticker] = useState(''); //sticker
  const [selectedFact, setSelectedFact] = useState<TargetPopupProps | null>(null);

  const doc = document.getElementById('target-popup')
  const drawer = document.getElementById('bottom-drawer')
  const stickers = document.getElementsByClassName('cantap');

  const bottomDrawToggle = () => {
    console.log('bottom drawer toggle')
    setShowBottomDrawer(!showBottomDrawer);
    setPopUpOpen(false);
  }

  useEffect(() => {

    const showPopUp = (event: any) => {

      console.log(showBottomDrawer === false, showBottomDrawer)

      if (!doc && !drawer) {
        console.log('IMAGE STICKER CLICKED', event)
        console.log(event.srcElement.id)
        const props = {
          logo: CoralReefFacts.get(event.srcElement.id)?.scanIcon || '',
          link: event.srcElement.id,
          title: CoralReefFacts.get(event.srcElement.id)?.factTitle,
          caption: CoralReefFacts.get(event.srcElement.id)?.previewText,
          setBottomDrawer: () => bottomDrawToggle()
        }
        setSelectedFact(props)

        setPopUpOpen(true);
        setInfoOpen(false);
        setSticker(event.detail.name) //set sticker name
      }

    }

    const hidePopUp = () => {
      console.log('Hide pop Up')
      const doc = document.getElementById('target-popup')

      if (doc) {
        setPopUpOpen(false);
        console.log('Hide pop Up')
      }

    }

    const showInfo = () => {
      setInfoOpen(true);
      console.log('Show info')

    }

    const hideInfo = () => {
      setInfoOpen(false);
      console.log('hide info')
    }

    // Event listners    

    Array.from(stickers)?.forEach(el => el.addEventListener('click', (event: any) => {
      showPopUp(event);
      hideInfo();
    }));

    // used to close target popup. Currently only works when you 
    // tap inside the target popup div
    const blocker = document.getElementById('blocker');
    blocker?.addEventListener('click', function (event: any) {
      console.log('clicked outside of target 1')

      if (!doc?.contains(event.target)) {
        console.log('clicked outside of target 2')
        hidePopUp();
      }
    });

    window.addEventListener('xrimagefound', (event) => {
      console.log('XR8 image Loaded', event);
      hideInfo();
    })

    window.addEventListener('xrimagelost', (event) => {
      console.log('XR8 image Lost', event);
      const doc = document.getElementById('bottom-drawer');
      if(!doc) {
        showInfo();}
    })

    //loadingContainer
  })
  return (
    <div id="image-target">
      {!hideInfo && <InfoComponent isOpen={infoOpen} setIsOpen={setInfoOpen} />}
      {popUpOpen && selectedFact !== null && <TargetPopup {...selectedFact} />}
      {showBottomDrawer && <BottomDrawer isOpen={(val: any) => {setShowBottomDrawer(val); setPopUpOpen(false); setHideInfo(true);}} onClose={() => setHideInfo(false)} />}
      <AFrameScene
        sceneHtml={imageHtml}
        imageTargets={['sticker-1.1','sticker-1.3','sticker-1.6','sticker-1.7','sticker-2.2','sticker-2.4','sticker-2.6','sticker-2.8']}        
      />
    </div>
  )
};

export { ImageTarget }