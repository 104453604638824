import React from 'react';
import './LoadingScreen.css';
import ReefCloudText from '../assets/Media/Loading-Screen-Images/reefCloudText.svg';
import { ReactComponent as ReefCloudLogo } from '../assets/Media/Coral.svg';

function LoadingScreen() {

  return (
    <div className='loading-screen-container'>
      <div className="loading-screen-bg">
        <div className='loading-screen-wrapper' >
          <ReefCloudLogo className='coral-logo' />
          <img src={ReefCloudText} className='ls-reef-cloud-text' />
        </div>
      </div>
    </div>
  );
}

export default LoadingScreen;