import React, { useEffect, useRef, useState } from 'react';
import './AboutPage.css'
import leftarrow from '../assets/leftarrow.png'
import Divider from '../assets/Divider.png'
import greenInfo from '../assets/green-info.svg'
import { useNavigate } from 'react-router-dom';
import backToTop from '../assets/back-to-top.svg'
import one from '../assets/one.svg'
import two from '../assets/two.svg'
import three from '../assets/three.svg'
import four from '../assets/four.svg';
import five from '../assets/five.svg';
import linkPng from '../assets/link.svg';
import mail from '../assets/mail.svg';
import getStarted from '../assets/getStarted.svg';

interface AboutPageBottomSectionProps {
    title?: string,
    subtitle?: string,
    link: string,
}

const AboutPageBottomSection = ({ title, subtitle, link }: AboutPageBottomSectionProps) => {
    const navigate = useNavigate();

    return (
        <span className='about-bottomContainer'>
            <div className='about-bottomDiv'>
                <div className='about-bottomLeft'>
                    <span className='about-bottomTitle'>{title}</span>
                    <span className='about-bottomSubtitle'>       {subtitle}</span>
                </div>
                <span className="about-bottom-span">
                <a rel="noreferrer" target="_blank" href={link} >
                    <img src={linkPng} />

                </a>
                </span>
            </div>
            <div> <img src={Divider} className="aboutBottom-divider" /> </div>

        </span>
    )
}

const AboutPage = () => {

    const [hideButton, setHideButton] = useState(true);

    const navigate = useNavigate();
    const topRef = useRef<HTMLDivElement>(null);
    const getInTouchRef = useRef<HTMLDivElement>(null);
    const aboutReefCloud = useRef<HTMLDivElement>(null);
    const accenturesInvolvement = useRef<HTMLDivElement>(null);
    const attributions = useRef<HTMLDivElement>(null);
    const submitPhoto = useRef<HTMLDivElement>(null);

    const showButton = () => {
        const heightToHideFrom = 20;
        const winScroll = document.body.scrollTop ||
            document.documentElement.scrollTop;
        if (winScroll < heightToHideFrom) {
            //    hideButton &&      // to limit setting state only the first time
            setHideButton(true);
        } else {
            setHideButton(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", showButton);
        return () =>
            window.removeEventListener("scroll", showButton);
    }, [])


    const scrollTo = (refName: string) => {

        switch (refName) {
            case "get-in-touch":
                window.scrollTo({ behavior: 'smooth', top: getInTouchRef.current?.offsetTop })
                break;
                case "submit-photo":
                    window.scrollTo({ behavior: 'smooth', top: submitPhoto.current?.offsetTop })
                    break;
            case "about-reefcloud":
                window.scrollTo({ behavior: 'smooth', top: aboutReefCloud.current?.offsetTop })
                break;
            case "accentures-involvement":
                console.log()
                window.scrollTo({ behavior: 'smooth', top: accenturesInvolvement.current?.offsetTop })
                break;
            case "attributions":
                window.scrollTo({ behavior: 'smooth', top: attributions.current?.offsetTop })
                break;
            case "top":
                window.scrollTo({ behavior: 'smooth', top: topRef.current?.offsetTop })
                break;
            default:
                break;
        }
    }



    const mailTo = () => {
        console.log('mail')
        location.href = "mailto:reefcloud-reinvent@accenture.com?Subject=Reef Cloud Interest";
    }

    const getInTouch = () => {
        console.log('get in touch')
        navigate('/upload-images')
    }

    return (
        <div id="information-page" ref={topRef}>
            {!hideButton && <div className="about-sticky" onClick={() => scrollTo("top")}><img src={backToTop} /></div>}
            <div onClick={() => navigate('/image-target')}> <img src={leftarrow} /> </div>
            <div className="about-header"> <img src={greenInfo} /> About this project</div>
            <div className="about-numbered-container">
                <div className="about-numbered" onClick={() => scrollTo("get-in-touch")}>
                    <img src={one} />
                    Get in touch
                </div>
                <div className="about-numbered" onClick={() => scrollTo("submit-photo")}>
                    <img src={two} />
                    Submit your own photos
                </div>
                <div className="about-numbered" onClick={() => scrollTo("about-reefcloud")}>
                    <img src={three} />
                    About ReefCloud
                </div>
                <div className="about-numbered" onClick={() => scrollTo("accentures-involvement")}>
                    <img src={four} />
                    Accenture’s Involvement
                </div>
                <div className="about-numbered" onClick={() => scrollTo("attributions")}>
                    <img src={five} />
                    Attributions
                </div>
            </div>

            <div className="about-divider" ref={getInTouchRef}> <img src={Divider} /> </div>

            <div className="about-subtitle" > Get in touch </div>
            <span> Want to know more about this project and Accenture’s cloud capabilities? Get in touch with us and stay up to date on the latest information!</span>
            <div className='about-button' onClick={() => mailTo()}> <img src={mail} /> <span>Get in touch </span></div>

            <div className="about-divider" ref={submitPhoto}> <img src={Divider} /> </div>

            <div className="about-subtitle" > Submit your own photos </div>
            <span>Have your own photos of coral reefs that you would like to share? Upload your photos to support the preservation of Coral Reefs around the world! <br /><br/>

               <span style={{fontStyle: 'italic'}}> Once uploaded AIMS will use automated image analysis to add your photo to the dataset scientists use to monitor the health of coral reefs around the world</span></span>
            <div className='about-button' onClick={() => getInTouch()}> <img src={getStarted} /> <span> Get started</span> </div>

            <div className="about-divider" ref={aboutReefCloud}> <img src={Divider} /> </div>

            <div className="about-subtitle" > About ReefCloud </div>
            <span> Up to 50% of the world’s coral reefs have already been severely damaged. 90% will be critically endangered by 2050 if we do nothing about it. Conservation practitioners worldwide face a shared and urgent challenge: coral reefs are degrading faster than we can track. Limited resources, capacity differences, and inconsistencies in survey methodologies weaken the bridge between scientific knowledge and management decision-making. </span>

            <span>ReefCloud is a transformational digital tool from the Australian Institute of Marine Science (AIMS) built on AWS that allows the world’s coral reef monitoring community to overcome these challenges and deliver insights faster to support conservation. Harnessing the power of human collaboration and artificial intelligence, ReefCloud provides comprehensive, timely, and robust insights on coral reef conditions to inform policy-making and conservation efforts.</span>
            <div className="about-divider" ref={accenturesInvolvement}> <img src={Divider} /> </div>

            <div className="about-subtitle" > Accenture’s Involvement </div>
            <span> Accenture was involved in various phases of the ReefCloud development. In the initial phase they provided AIMS with various computer vision methods to better approach coral reef monitoring from image analysis. AIMS used these approaches to iterate on and improve their models and analysis. </span>

            <span>In phase 2 Accenture focused on identifying key coral preservation challenges and ways to systematically support those efforts through the ReefCloud platform. To ensure the platform and pipeline is scalable and durable Accenture provided a cloud solution architecture plan. The pipeline is crucial to ReefCloud’s mission and is how they’ve created a data standard for marine scientists to use for coral preservation.</span>

            <div className="about-divider" ref={attributions}> <img src={Divider} /> </div>

            <div className="about-subtitle" > Attributions </div>


            <span> The below are licensed under <a className="about-a" rel="noreferrer" target="_blank" href="https://skfb.ly/6YX6J">Creative Commons Attribution:</a> </span>

            <div className="about-bottom">
                <AboutPageBottomSection title="Coral" subtitle='By RISD Nature Lab' link="https://skfb.ly/6YX6J" />
                <AboutPageBottomSection title="Staghorn coral" subtitle='By RISD Nature Lab' link="https://skfb.ly/6RWPQ" />
                <AboutPageBottomSection title="Flower Coral Skeleton" subtitle='By RISD Nature Lab' link="https://skfb.ly/6TDHG" />
                <AboutPageBottomSection title="Brain Coral" subtitle='By RISD Nature Lab' link="https://skfb.ly/6TDIo" />

                <AboutPageBottomSection title="Coral" subtitle='By RISD Nature Lab' link="https://skfb.ly/6YX6N" />
                <AboutPageBottomSection title="Coral" subtitle='By RISD Nature Lab' link="https://skfb.ly/6YCR7" />
                <AboutPageBottomSection title="Blastomussa coral fragment" subtitle='By RISD Nature Lab' link="https://skfb.ly/6WRrR" />
                <AboutPageBottomSection title="Red Organ Pipe Coral" subtitle='By RISD Nature Lab' link="https://skfb.ly/6TABq" />
                <AboutPageBottomSection title="Rose brain coral fragment" subtitle='By RISD Nature Lab' link="https://skfb.ly/6YyTC" />

                <AboutPageBottomSection title="Brain Coral" subtitle='By RISD Nature Lab' link="https://skfb.ly/6VG67" />
                <AboutPageBottomSection title="Coral" subtitle='By RISD Nature Lab' link="https://skfb.ly/6YCRo" />
                <AboutPageBottomSection title="coral fish" subtitle='By polyplant3d' link="https://skfb.ly/JKRo" />
                <AboutPageBottomSection title="School Of Fish" subtitle='By Titanas YT' link="https://skfb.ly/6WpRz" />


                <AboutPageBottomSection title="Coral Uitstoot Gezond" subtitle='By carolienvanbedaf' link="https://skfb.ly/oyo9H" />
                <AboutPageBottomSection title="Coral Uitstoot Dood Wit" subtitle='By carolienvanbedaf' link="https://skfb.ly/oypZI" />
                <AboutPageBottomSection title="Coral Verzuring Gezond" subtitle='By carolienvanbedaf' link="https://skfb.ly/oyoI8" />
            </div>

            <span> The below are licensed under <a className="about-a" rel="noreferrer" target="_blank" href="http://creativecommons.org/licenses/by-nc-nd/4.0/">CC Attribution-NonCommercial-NoDerivs: </a></span>

            <div className="about-bottom">
                <AboutPageBottomSection title="Heliopora sp" subtitle='By California Academy of Sciences Viz Studio' link="https://skfb.ly/UMSr" />
            </div>

            <span> The below are licensed under Creative Commons Attribution-NonCommercial </span>

            <div className="about-bottom">
                <AboutPageBottomSection title="Fossil coral 1" subtitle='By Sara Carena' link="https://skfb.ly/6VDr8" />
            </div>
        </div>
    )
};

export { AboutPage }