import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import SkipBtn from '../assets/skip-btn.svg';
import NextBtn from '../assets/next-btn.svg';
import GettingStartedBtn from '../assets/getstarted-btn.svg' 
import slides from './info-slides';
import { AnimatePresence, motion} from 'framer-motion';
import './info-page.css';


export function InfoPage() {
    
    const navigate = useNavigate();
    const [current, setCurrent] = useState(0);
    const [prev, setPrev] = useState(1);
    const length = slides.length;

    const ctaBtns = document.getElementById('cta-container') as HTMLElement | null;
    const getStartedBtn = document.getElementById('getting-started-btn') as HTMLElement | null;

    //Used for specfiing the animations
    const variants_current = {
        initial: {
            opacity: 0
        },
        animate: {
            opacity: 1,
        },
        exit: {
            opacity: 1
        },
        
    }

    const variants_prev = {
        initial: {
            opacity: 1
        },
        animate: {
            opacity: 0, 
        },
        exit: {
            opacity: 0
        },
        
    }


    const Next = () => {

        if (current <= length - 1){

            //On last page, hides skip & next btn and displays Get Started Btn 
            if (current == length - 2 ){
                ctaBtns!.style.display = 'none';
                getStartedBtn!.style.display = 'flex';
            }   
        }
        else{
            navigate('/image-target');
        }

        setCurrent((current + 1) % length)
        setPrev((prev == 0 ? current : current))    
    }

    const Skip = () => {
        navigate('/image-target');
    }


    return(
        <div className='info-container'>
            <AnimatePresence initial={false}>
            <div className='info-image-container'>
                <div className='info-image-current'>
                    <motion.img 
                    variants={variants_current} 
                    animate='animate' 
                    initial='initial' 
                    exit='exit' 
                    transition={{duration: 1}}
                    key={slides[current].src}
                    id='image_current' src={current == 0 ? slides[current].src : slides[current].src} height='auto' width='100%'/>
                </div>
                
                <div className='info-image-prev'>
                    <motion.img 
                    variants={variants_prev} 
                    animate='animate' 
                    initial='initial' 
                    exit='exit' 
                    transition={{duration: 1}}
                    key={slides[prev].src}
                    id='image_prev'  src={current == 0 ? slides[current].src : slides[current - 1].src} height='auto' width='100%'/>
                </div>
            </div>

            <div className='info-bottom-container'>
                <div className='info-indicator-container'>
                    <div id='info-indicator-1' className='info-indicator-1' style={{order:current + 1}}></div>
                    <div className='info-indicator-2'></div> 
                    <div className='info-indicator-3'></div>
                    <div className='info-indicator-4'></div>
                    <div className='info-indicator-5'></div>
                </div>

                <div className='info-bottom-content-container'>

                    <div className='info-content-container-current'>
                        <motion.div
                        variants={variants_current}
                        animate='animate' 
                        initial='initial' 
                        exit='exit' 
                        transition={{duration: 1}}
                        key={slides[current].header}
                        id='header-current' className='info-content-header-current'>
                            {slides[current].header}
                        </motion.div>

                        <motion.div 
                        variants={variants_current}
                        animate='animate' 
                        initial='initial' 
                        exit='exit' 
                        transition={{duration: 1}}
                        key={slides[current].sub}
                        id='sub-current' className='info-content-sub-current'>
                            {slides[current].sub}
                        </motion.div>
                    </div>

                    <div className='info-content-container-prev'>
                        <motion.div
                        variants={variants_prev}
                        animate='animate' 
                        initial='initial' 
                        exit='exit' 
                        transition={{duration: 1}}
                        key={slides[prev].header}
                        id='header-prev' className='info-content-header-prev'>
                            {current == 0 ? slides[current].header : slides[current-1].header}
                        </motion.div>

                        <motion.div
                        variants={variants_prev}
                        animate='animate' 
                        initial='initial' 
                        exit='exit' 
                        transition={{duration: 1}}
                        key={slides[prev].sub}
                        id='sub-prev' className='info-content-sub-prev'>
                            {current == 0 ? slides[current].sub : slides[current-1].sub}
                        </motion.div>
                    </div>

                    <div className='space-box'></div>

                    <div id='cta-container' className='info-cta-container'>
                        <div id='cta-skip-btn' className='info-cta-skip-button' onClick={Skip}>
                        <img src={SkipBtn}/>
                        </div>
                        <div id='cta-next-btn' className='info-cta-next-button' onClick={Next}> 
                        <img src={NextBtn}/>
                        </div>
                    </div>
                    <div id='getting-started-btn' className='info-get-started-button' onClick={Skip}>
                        <img src={GettingStartedBtn}/>
                    </div>
                </div>

            </div>
            </AnimatePresence>
        </div>

    );
}