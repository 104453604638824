import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { TextField } from "@mui/material";
import './UploadAndDisplayImage.css';
import axios from "axios";
import reefCloudLogo from '../assets/reef-cloud-logo.svg'



const UploadAndDisplayImage = () => {
  const [images, setImages] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [imageURLS, setImageURLs] = useState([]);
  const hiddenFileInput = React.useRef(null);
  const [locationName, setLocationName] = useState();

  const [dateVal, setDateVal] = React.useState([]);
  const [successAlert, setSuccessAlert] = React.useState(false);
  const [failureAlert, setFailureAlert] = React.useState(false);
  const [failureMessage, setFailureMessage] = React.useState(undefined);
  useEffect(() => {
    setImageList(images);
    if (images.length < 1) return;
    const newImageUrls = [];
    images.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
    setImageURLs(newImageUrls);
  }, [images]);

  function onImageChange(e) {
    setImages([...e.target.files]);

  }
  const getLocationName = (e, i) => {
    images[i]["location"] = e.target.value;
  };

  const getImgDate = (e, index) => {
    images[index]["date"] = e.target.value;
  }
  const handleClick = event => {
    hiddenFileInput.current.click();
  };


  const onImageUpload = (index) => {
    // check to see if index has required tags
    if (images[index]["date"] && images[index]["location"]) {
      processPhoto(images[index]);

    } else {
      // need to set alerts
      setFailureMessage("Image is missing date or location tags");
      setFailureAlert(true);
      setTimeout(() => { setFailureAlert(false); }, 3000);
    }


  }
  const processPhoto = (file) => {
    //console.log("final file", file)
    if (file) {
      //var file = file;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        const fileSize = file.size;
        // console.log('File size: ' + fileSize);
        if (fileSize <= 4500000) {
          uploadToS3(reader.result, file);
        } else {
          setFailureMessage("Image is over the file limit of 4.5mb.");
          setFailureAlert(true);
        }
      }
    }
  }

  // this will upload to S3
  const uploadToS3 = (file_content, file) => {
    const requestContent = {
      body: {
        imageBinary: file_content,
        fileName: file["name"],
        fileType: file["type"],
        tags: "location=" + file["location"] + "&date=" + file["date"]
      },
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
    }
    
    console.log("req", requestContent)
    //make Axios request here with the requestContent, I have included example code from how I make my call to API gateway
    var uploadPromise = axios.post('https://v7vgz8ub02.execute-api.us-east-1.amazonaws.com/dev/store-photos', requestContent)
    uploadPromise.then(function(data) {
       setSuccessAlert(true);
       console.log(data);
       setTimeout(() => { setSuccessAlert(false);}, 1500);
     }).catch(function(err) {
         console.log("failed: ", err)
        // here is where we set the alert flag
         setFailureMessage("Image upload failed: " + err);
         setFailureAlert(true);
         setTimeout(() => { setFailureAlert(false);}, 3000);
     })

  };

  return (
    <div className="Image-page">
      <div className="image-container">
        <div className="upload-header-container">
          <img src={reefCloudLogo}/>
          <h3>Upload photos of coral up to 4.5 MB! The two required fields are:</h3>

          <ul className="upload-list">

            <li>Location (lat/lon if possible if not closest reef or beach)</li>

            <li>Date (best guess is okay if you aren&apos;t 100% sure)</li>

          </ul>

        </div>
        <Button variant="contained" className="uploadBtn fileUploadBtn" onClick={handleClick}>
          Upload Files
        </Button>
        {failureAlert && (
          <Alert severity="error" className="alert">
            <AlertTitle>Error</AlertTitle>
            {failureMessage}
          </Alert>

        )}
        {successAlert && (
          <Alert severity="success" className="alert">
            <AlertTitle>Success</AlertTitle>
            Image Uploaded!
          </Alert>
        )}

        <input type="file" multiple accept="image/*" ref={hiddenFileInput} onChange={onImageChange} style={{ display: 'none' }} />
        <div style={{ width: '90%', margin: '0 auto' }}>
          {imageURLS.map((imageSrc, index) => (
            <Card key={index} sx={{}} className="card" style={{}}>
              <CardMedia
                component="img"
                alt="green iguana"
                height="140"
                image={imageSrc}
              />
              <CardContent>
                <TextField sx={{ mb: '14px' }} id="standard-basic" label="Location*"
                  variant="standard" className="mb-10" name="location"
                  value={locationName} onChange={(e) => getLocationName(e, index)} InputLabelProps={{ style: { fontSize: 18 } }} inputProps={{ style: { fontSize: 14 } }} />

                <input className="dateInput" type='date' value={dateVal[index]?.value} onChange={(e) =>
                  getImgDate(e, index)} />
              </CardContent>
              <CardActions>
                <Button variant="outlined" className="uploadBtn" size="small" onClick={() => onImageUpload(index)}>Upload</Button>
              </CardActions>
            </Card>
          ))}
        </div>

      </div>
    </div>

  );
};

export default UploadAndDisplayImage;